<template>
  <b-flip-modal @close="close">
    <div slot="modal-body" :class="['row', 'center-xs', 'modal-wrapper-box']">
      <div class="modal-container">
        <div class="modal-header">
          <span class="com-title">{{ title }}</span>
        </div>
        <div v-if="error" class="error">{{ error }}</div>
        <div id="errorContainer">
          <div v-if="getErrors" class="error">{{ getErrors('updateError') }}</div>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-group">
              <div id="errorContainer">
                <div v-if="getErrors" class="error">{{ getErrors('name') }}</div>
              </div>
              <label for="name">Advertiser Name<span class="required">*</span></label>
              <input
                id="name"
                ref="nameInput"
                v-model="advertiser.name"
                type="text"
                aria-describedby="emailHelp"
                @input="validateName"
              />
              <div v-if="matchingAdvertisers.length > 0" class="advertiser-matches">
                <ul class="dropdown dropdown-menu">
                  <li v-for="(match, index) in matchingAdvertisers" :key="index">
                    {{ match.name }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-group">
              <div id="errorContainer">
                <div v-if="getErrors" class="error">{{ getErrors('xandrId') }}</div>
              </div>
              <label for="xandrId">Xandr ID</label>
              <input
                id="xandrId"
                ref="xandrIdInput"
                v-model="advertiser.xandrAdvertiserId"
                type="text"
                @input="validateAdvertiserXandrId"
              />
            </div>
            <div v-if="isTemplateIdEnabled" class="form-group">
              <label for="templateId">Template ID</label>
              <input
                id="templateId"
                ref="templateIdInput"
                v-model="advertiser.templateId"
                type="number"
              />
            </div>
            <div class="form-group form-check">
              <label class="form-check-label" for="customReportsEnabled">Custom Reports</label>
              <k-checkbox
                id="customReportsEnabled"
                :checked="advertiser.customReportsEnabled"
                name="advertiser.customReportsEnabled"
                @change="setCustomReportsEnabled"
                @selected="setCustomReportsEnabled"
              />
            </div>
            <div v-if="false" class="form-group form-check long-label">
              <label for="userlogin">&nbsp;</label>
              <k-checkbox
                :checked="createAdvertiserOrg"
                name="add.org"
                label="Enable user login?"
                @change="setCreateAdvertiserOrg"
                @selected="setCreateAdvertiserOrg"
              />
            </div>

            <br />
            <div class="form-group" style="text-align: right; float:right; display: contents;">
              <div class="actions-btn">
                <k-button :size="3" label="SAVE" class="mr-20" @click="handleAdvertiser" />
                <k-button :size="3" label="CLOSE" @click="close" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </b-flip-modal>
</template>

<script>
import { get } from 'vuex-pathify';
import { isBlank } from 'adready-api/helpers/common';
import organizationApi from 'adready-api/api/organization';
import { PRIVILEGE_TO_CREATE, E_ORGANIZATION } from 'adready-api/auth-constant';
import { TEMPLATE_ID_ENABLED_FOR_ACCOUNTS } from '@/constant';
import advertiserApi from '~/api/advertiser';
import forklift from '~/components/mixins/forklift-mixin';
import authzMixin from '~/components/mixins/authz-mixin';

export default {
  components: {
    BFlipModal: () =>
      import(/* webpackChunkName: "b-flip-modal" */ '~/components/elements/b-flip-modal.vue'),
  },
  mixins: [forklift, authzMixin],
  props: {
    accountId: {
      type: Number,
      required: false,
      default: 0,
    },
    accountRef: {
      required: false,
      default: null,
      type: Object,
    },
    initialAdvertiser: {
      required: true,
      default() {
        return {};
      },
      type: Object,
    },
  },
  data() {
    const advertiser = JSON.parse(JSON.stringify(this.initialAdvertiser));
    const existingAccount = JSON.parse(JSON.stringify(this.accountRef));

    return {
      advertiser,
      accountData: existingAccount || {},
      createAdvertiserOrg: false,
      loading: false,
      errorMsg: null,
      errors: [],
      E_ORGANIZATION,
      PRIVILEGE_TO_CREATE,
    };
  },
  computed: {
    allAccounts: get('common/accounts'),
    currentUser: get('common/currentUser'),
    organizations: get('common/allOrganizations'),
    title() {
      return this.initialAdvertiser.id ? 'Edit Advertiser' : 'New Advertiser';
    },
    error() {
      if (!isBlank(this.errorMsg)) {
        return this.errorMsg;
      }
      return null;
    },
    getAccounts() {
      if (this.isInternalAdmin) {
        return this.allAccounts;
      }
      return this.userAccounts;
    },
    /**
     * Filters advertisers on the current account selected
     */
    getAdvertisers() {
      // Find the account that matches this.accountData.name
      const matchingAccount = this.getAccounts.find(
        (account) => account.name === this.accountData.name
      );

      // If a matching account is found, filter its advertisers; otherwise, return an empty array
      return matchingAccount ? matchingAccount.advertisers : [];
    },
    matchingAdvertisers() {
      const advertisers = this.getAdvertisers;
      if (isBlank(advertisers) || !advertisers.length || isBlank(this.advertiser.name)) {
        return [];
      }
      return advertisers.filter(
        (advertiser) =>
          !isBlank(advertiser.name) &&
          advertiser.name.toLowerCase().includes(this.advertiser.name.toLowerCase())
      );
    },
    isTemplateIdEnabled() {
      return TEMPLATE_ID_ENABLED_FOR_ACCOUNTS.includes(this.accountData.id);
    },
  },
  methods: {
    clearNameError() {
      this.errors = this.errors.filter((item) => item.name !== 'name');
      if (this.$refs.nameInput) this.$refs.nameInput.classList.remove('errorBox');
    },
    clearXandrIdError() {
      this.errors = this.errors.filter((item) => item.name !== 'xandrId');
      if (this.$refs.xandrIdInput) this.$refs.xandrIdInput.classList.remove('errorBox');
    },
    validateName() {
      this.clearNameError();

      if (isBlank(this.advertiser.name)) {
        this.$refs.nameInput.focus();
        this.$refs.nameInput.classList.add('errorBox');
        const error = { name: 'name', message: 'Please enter advertiser name' };
        if (!this.errors.find((item) => item.name === 'name')) this.errors.push(error);
        return false;
      }

      const advertiserFoundWithSameName =
        !isBlank(this.getAdvertisers) &&
        this.getAdvertisers.some(
          (advertiser) =>
            !isBlank(advertiser.name) &&
            !isBlank(this.advertiser.name) &&
            advertiser.id !== this.advertiser.id &&
            advertiser.name.toUpperCase() === this.advertiser.name.toUpperCase()
        );

      if (advertiserFoundWithSameName) {
        const error = {
          name: 'name',
          message: `Advertiser already exists with ${this.advertiser.name} name`,
        };
        if (!this.errors.find((item) => item.name === 'name')) this.errors.push(error);
        return false;
      }

      return true;
    },

    validateAdvertiserXandrId() {
      this.clearXandrIdError();

      if (isBlank(this.advertiser.xandrAdvertiserId)) {
        return true;
      }

      if (
        this.advertiser.xandrAdvertiserId === '0' ||
        Number.isNaN(Number(this.advertiser.xandrAdvertiserId))
      ) {
        this.$refs.xandrIdInput.focus();
        this.$refs.xandrIdInput.classList.add('errorBox');
        const error = { name: 'xandrId', message: 'Please enter valid Xandr ID' };
        if (!this.errors.find((item) => item.name === 'xandrId')) this.errors.push(error);
        return false;
      }

      const advertiserFoundWithSameXandrAdvertiserId =
        !isBlank(this.allAdvertisers) &&
        this.allAdvertisers.some(
          (advertiser) =>
            !isBlank(advertiser.xandrAdvertiserId) &&
            advertiser.xandrAdvertiserId.toString() === this.advertiser.xandrAdvertiserId
        );

      if (advertiserFoundWithSameXandrAdvertiserId) {
        const error = {
          name: 'xandrId',
          message: `The ${this.advertiser.xandrAdvertiserId} Xandr ID has been assigned to another advertiser`,
        };
        if (!this.errors.find((item) => item.name === 'xandrId')) this.errors.push(error);
        return false;
      }

      return true;
    },
    setCreateAccountOrg(val) {
      this.createAccountOrg = val.checked;
    },
    async createOrganization(newOrganizationName, isCreateOrg) {
      let createdOrg;
      try {
        createdOrg = await organizationApi.create({
          name: newOrganizationName,
          loginEnabled: isCreateOrg,
        });
        this.loadAllOrganizations(true);
      } catch (err) {
        const error = { name: 'updateError', message: 'Failed while adding the organization' };
        if (!this.errors.find((item) => item.name === 'updateError')) this.errors.push(error);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
      return createdOrg;
    },
    async updateOrganization(currentOrganizationId, newOrganizationName, isCreateOrg) {
      let updatedOrg;
      try {
        updatedOrg = await organizationApi.update(currentOrganizationId, {
          name: newOrganizationName,
          loginEnabled: isCreateOrg,
        });
        this.loadAllOrganizations(true);
      } catch (err) {
        const error = { name: 'updateError', message: 'Failed while adding the organization' };
        if (!this.errors.find((item) => item.name === 'updateError')) this.errors.push(error);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
      return updatedOrg;
    },
    async handleAdvertiser() {
      const dataValidated = this.validateData();
      if (!dataValidated) {
        return;
      }

      try {
        this.loading = true;
        if (!this.initialAdvertiser.id) {
          await this.addAdvertiser();
        } else {
          await this.updateAdvertiser();
        }
      } catch (err) {
        console.error('error handling advertiser ->', err);
      } finally {
        this.loading = false;
        if (isBlank(this.errors)) {
          this.close();
        }
      }
    },
    validateData() {
      this.clearNameError();
      this.clearXandrIdError();
      if (!this.validateName() || !this.validateAdvertiserXandrId()) {
        return false;
      }
      this.errors = [];
      return true;
    },
    async addAdvertiser() {
      const advertiser = JSON.parse(JSON.stringify(this.advertiser));
      advertiser.accountId = this.accountData.id;
      try {
        // Create organizationId for Advertiser
        const createdAdvertiserOrg = await this.createOrganization(
          advertiser.name,
          this.createAccountOrg
        );
        advertiser.organizationId = createdAdvertiserOrg.id;
        await advertiserApi.createAdvertiser(advertiser);
        this.loadUserAccounts(this.currentUser.id, true);
      } catch (err) {
        const error = { name: 'updateError', message: 'Failed while adding the advertiser' };
        if (!this.errors.find((item) => item.name === 'updateError')) this.errors.push(error);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
    },
    async updateAdvertiser() {
      const advertiser = JSON.parse(JSON.stringify(this.advertiser));

      try {
        await advertiserApi.editAdvertiser(this.initialAdvertiser.id, advertiser);
        this.loadUserAccounts(this.currentUser.id, true);
      } catch (err) {
        const error = { name: 'updateError', message: 'Failed while updating the advertiser' };
        if (!this.errors.find((item) => item.name === 'updateError')) this.errors.push(error);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
    },

    close() {
      this.$emit('close');
    },

    getErrors(fieldName) {
      const errSet = new Set(this.errors);
      const errArr = Array.from(errSet);
      const errItem = errArr.find((item) => item.name === fieldName);
      if (errItem) {
        const idElement = document.getElementById('errorContainer');
        if (idElement) {
          idElement.style.display = 'block';
        }
        const errMessage = errItem.message ? errItem.message : null;
        return errMessage;
      }
      return null;
    },
    setCustomReportsEnabled(val) {
      this.advertiser.customReportsEnabled = val.checked;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-mask {
  .modal-container {
    background-color: #212429;
    min-height: 130px;
  }
  .modal-wrapper {
    margin: 0 auto;
  }
}
/**custom */
.list-box {
  position: relative;
  z-index: 2;
  color: #fff;
  background-color: #212429;
  border-radius: 0;
  padding: 30px 20px;
  width: 100%;
  display: block;
  min-width: 800px;
  max-width: 800px;
  margin: 0 auto;
  ul {
    margin-bottom: 10px;
    ul {
      border: 0;
      margin: 0;
    }
    li {
      font-size: 12px;
      line-height: 0.75rem;
      color: rgba(130, 130, 140, 0.8);
      padding-bottom: 5px;
      padding-top: 5px;
      margin-bottom: 0;
      position: relative;

      li {
        border-bottom: 1px solid rgba(170, 170, 170, 0.1);
        padding: 15px 15px 15px 10px;
        list-style: none;
        list-style-position: inside;
        font-size: 13px;
        a {
          color: #848890;
        }
        .box {
          svg {
            color: #848890;
            &:hover {
              color: var(--adminprimarycolor) !important;
            }
          }
        }
      }
      a {
        color: #cad1d6;
        display: inline;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .button-box {
    position: absolute;
    top: 5px;
    right: 0;
    line-height: 30px;
    .box {
      display: inline-block;
      margin-left: 10px;
    }
  }
}
.modal-wrapper-box {
  width: 100%;
  min-width: 500px;
  max-width: 650px;
  padding: 15px;
  margin: 0 auto;
  color: #666;
  background-color: #212429;
  font-size: 12px;
  line-height: 0.75rem;
  color: rgba(130, 130, 140, 0.8);
  .modal-header {
    margin-bottom: 10px;
    h2 {
      text-align: center;
      color: #fff;
      line-height: 1.3;
      padding-bottom: 10px;
    }
    p {
      border-top: 2px solid white;
      text-align: center;
      font-size: 18px;
      padding: 20px 0;
      margin: 0;
      color: #fff;
    }
  }
  .modal-body {
    width: 600px;
  }
  .form-group {
    margin-bottom: 15px;
    overflow: hidden;
    position: relative;
    color: #fff;
    .add-btn {
      position: absolute;
      right: 0;
      top: 0;
      color: #cad1d6;
      border: none;
      background-color: var(--adminprimarycolor);
      font-size: 16px;
      padding: 14px;
      border-radius: 0 4px 4px 0;
      line-height: 12px;
    }
    .list-url {
      clear: both;
      width: 80%;
      line-height: 25px;
      float: right;
      font-size: 13px;
      margin-top: -6px;
      min-height: 15px;
      color: #848890;
      margin-left: 120px;
      p {
        text-align: left;
      }
      a {
        display: inline;
        color: #848890;
      }
      span {
        font-size: 10px;
        top: -6px;
        color: #ff0202;
        left: 3px;
        position: relative;
      }
    }
    label {
      width: 20%;
      display: inline-block;
      font-size: 0.875rem;
      color: #cad1d6;
      letter-spacing: 0.07em;
      float: left;
      padding: 9px;
      line-height: 20px;
    }
    input {
      width: 80%;
      float: left;
      height: 30px;
      font-size: 0.875rem;
      display: block;
      min-height: 40px;
      padding: 8px 10px;
      color: #cad1d6;
      background-color: #323239;
      cursor: pointer;
      border-radius: 4px;
      border: 1px solid rgba(170, 170, 170, 0.1);
      &:hover {
        border: 1px solid var(--adminprimarycolor);
      }
    }
    select {
      width: 80%;
      float: left;
      height: 30px;
      font-size: 14px;
      color: #cad1d6;
      display: block;
      min-height: 40px;
      padding: 8px 10px;
      text-transform: capitalize;
      cursor: pointer;
      background-color: transparent;
      border: 1px solid rgba(170, 170, 170, 0.1);
      border-radius: 4px;
    }
    input[type='checkbox'] {
      width: auto;
      background-color: #323239;
      color: #cad1d6;
      &::before,
      &::after {
        background-color: #323239;
        color: #cad1d6;
      }
    }
    textarea {
      width: 80%;
      float: left;
      font-size: 0.875rem;
      display: block;
      min-height: 40px;
      padding: 8px 10px;
      color: #cad1d6;
      background-color: #323239;
      cursor: pointer;
      border-radius: 4px;
      height: 100px;
      resize: none;
      border: 1px solid rgba(170, 170, 170, 0.1);
      &:hover {
        border: 1px solid var(--adminprimarycolor);
      }
    }
    button {
      padding: 5px;
      font-size: 16px;
      margin-left: 10px;
      float: right;
    }
  }
}
.top-li {
  .button-box {
    top: 0;
  }
  a {
    svg {
      font-size: 19px;
      color: #ddd;
      &:hover {
        color: var(--adminprimarycolor) !important;
      }
    }
  }
}
.sub-li {
  padding: 10px 10px 10px 0;
  margin-top: 15px;
  // height: 30px;
  .button-box {
    top: -2px;
  }
  a {
    font-size: 14px;
    svg {
      font-size: 14px;
      color: #cad1d6;
      &:hover {
        color: var(--adminprimarycolor) !important;
      }
    }
  }
}
a {
  svg {
    color: #fff;
    &:hover {
      color: var(--adminprimarycolor) !important;
    }
  }
}
.com-title {
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.1em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #fff;
  margin-bottom: 20px;
}
.actions-btn {
  margin-bottom: 20px;
}
.mr-20 {
  margin-right: 20px;
}
::v-deep .k-btn {
  display: inline-block;
  min-width: 75px;
  font-weight: 600;
  color: #303335 !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: var(--adminprimarycolor);
  border-radius: 3px;
  appearance: none;
}
::v-deep .k-btn.btn-size-3 span.label {
  padding: 9px 19px;
  line-height: 20px;
  color: var(--adminovertext);
}
::v-deep .k-btn:hover {
  color: #cad1d6 !important;
  background-color: #424242 !important;
}
.line-hr {
}
.sm {
  font-size: 13px;
  font-weight: 600;
  font-style: italic;
  border-top: 1px solid var(--adminprimarycolor);
  padding: 10px 0 20px 0;
  width: 100%;
  text-align: left;
  color: #fff;
  margin-top: -20px;
}
tr.no-bg {
  line-height: 80px;
  &:hover {
    background-color: transparent !important;
  }
  input {
    width: 95% !important;
  }
  td {
    text-align: right;
    padding: 0 36px 0 8px;
  }
}
.mg-tp-20 {
  margin-top: 20px;
}
tr.no-bg-no-pg {
  line-height: 40px;
  &:hover {
    background-color: transparent !important;
  }
  input {
    width: 95% !important;
  }
  td {
    text-align: right;
    padding: 0 0 0 8px;
  }
}
td a {
  display: inline-block;
  margin-right: 8px;
}
.col-lh-40 {
  line-height: 40px;
  display: inline;
}
tr.no-bg-axnbtn {
  overflow: hidden;
  &:hover {
    background-color: transparent !important;
  }
  input {
    width: 95% !important;
  }
  td {
    text-align: right;
    padding: 0 36px 0 8px;
  }
}
.display-only {
  display: inline-block;
  top: 5px;
  clear: none;
  margin-left: 0;
}

.advertiser-matches {
  width: 80%;
  background-color: #555555;
  max-height: 130px;
  overflow: hidden auto;
}
.advertiser-matches ul li {
  padding: 3px 10px;
  text-align: start;
}
.advertiser-matches ul li:hover {
  background-color: #444444;
}
</style>
